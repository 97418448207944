.interna__excursoes{
	padding-top: 106px;
	padding-bottom: 150px;

	.titulo__excursoes{
		font-family: 'AclonicaRegular';
		font-size: 35px;
		line-height: 1.2;
		color: #5e2a03;
		text-align: center;
	}

	.segura__cards__excursoes{
		display: grid;
		grid-template-columns: repeat(3, 1fr);

		gap: 30px;

		padding-top: 40px;
		padding-bottom: 100px;

		@include media-breakpoint-down(md){
			grid-template-columns: repeat(1, 1fr);
		}

		.card__excursao{
			text-decoration: none;

			margin-left: auto;
			margin-right: auto;

			.card__excursao__header{
				position: relative;

				&:after{
					background-image: url(../images/detalhe-excursao.png);
					background-repeat: no-repeat;
					background-size: auto;
					background-position: center;
					width: 350px;
					height: 50px;
					z-index: 2;

					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					opacity: 0;
				}
			}

			.card__excursao__body{
				background-color: #5e2a03;

				padding: 20px 30px;
				position: relative;

				&:after{
					background-image: url(../images/detalhe-botao.png);
					background-repeat: no-repeat;
					background-size: auto;
					background-position: center;
					width: 350px;
					height: 15px;
					z-index: 1;

					content: '';
					position: absolute;
					left: 0;
					top: -15px;
				}

				.nome__excursao{
					font-family: 'AclonicaRegular';
					font-size: 16px;
					line-height: 1.2;
					color: #FFF;
				}
			}

			&:hover{

				.card__excursao__header{

					&:after{
						opacity: 1;
						transition: all 0.5s linear;
					}
				}
			}
		}
	}

	.btn__ver__mais{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 250px;
		height: 60px;

		border: none;
		border-radius: 30px;

		background-color: #5e2a03;
		color: #FFF;

		font-family: 'PoppinsSemiBold';
		font-size: 16px;
		color: #FFF;
		text-decoration: none;

		margin-right: auto;
		margin-left: auto;
	}
}

