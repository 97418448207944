.interna__dicas{
	padding-top: 106px;
	padding-bottom: 150px;

	.nome__dicas{
		font-family: 'AclonicaRegular';
		font-size: 35px;
		line-height: 1.2;
		color: #5e2a03;
		text-align: center;
	}

	.row__personalizada{
		padding-top: 60px;

		.col__imagem{

			.owl__imagens{

				.owl-nav {
					display: flex;
					align-items: center;
					justify-content: center;

					margin-top: 10px;

					&.disabled{
						display: none;
					}

					button.owl-next {
						display: flex;
						align-items: center;
						justify-content: center;

						width: 40px;
						height: 40px;

						background-color: #f9bb25;

						border: none;
						border-radius: 50%;

						font-size: 22px;
						color: #FFF;

						margin: 0px 5px;
					}

					button.owl-prev {
						display: flex;
						align-items: center;
						justify-content: center;

						width: 40px;
						height: 40px;

						background-color: #f9bb25;

						border: none;
						border-radius: 50%;

						font-size: 22px;
						color: #FFF;

						margin: 0px 5px;
					}
				}
			}
		}

		.col__desc{

			@include media-breakpoint-down(md){
				padding-top: 30px;
			}

			.desc__dicas{
				font-family: 'PoppinsRegular';
				font-size: 16px;
				line-height: 1.4;
				color: #666666;
				text-align: justify;
			}

			.btn__voltar{
				display: flex;
				align-items: center;
				justify-content: center;

				width: 250px;
				height: 60px;

				border: none;
				border-radius: 50px;

				background-color: #f9bb25;

				font-family: 'PoppinsBold';
				font-size: 16px;
				line-height: 1.2;
				color: #FFF;
				text-decoration: none;

				margin-left: auto;
				margin-top: 30px;
			}
		}
	}
}

