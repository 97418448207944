@font-face {
    font-family: 'AclonicaRegular';
    src: url('../fonts/AclonicaRegular/Aclonica-Regular.eot');
    src: url('../fonts/AclonicaRegular/Aclonica-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AclonicaRegular/Aclonica-Regular.woff2') format('woff2'),
        url('../fonts/AclonicaRegular/Aclonica-Regular.woff') format('woff'),
        url('../fonts/AclonicaRegular/Aclonica-Regular.ttf') format('truetype'),
        url('../fonts/AclonicaRegular/Aclonica-Regular.svg#Aclonica-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'NotoSansBold';
    src: url('../fonts/NotoSansBold/NotoSans-Bold.eot');
    src: url('../fonts/NotoSansBold/NotoSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NotoSansBold/NotoSans-Bold.woff2') format('woff2'),
        url('../fonts/NotoSansBold/NotoSans-Bold.woff') format('woff'),
        url('../fonts/NotoSansBold/NotoSans-Bold.ttf') format('truetype'),
        url('../fonts/NotoSansBold/NotoSans-Bold.svg#NotoSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'NotoSansRegular';
    src: url('../fonts/NotoSansRegular/NotoSans-Regular.eot');
    src: url('../fonts/NotoSansRegular/NotoSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NotoSansRegular/NotoSans-Regular.woff2') format('woff2'),
        url('../fonts/NotoSansRegular/NotoSans-Regular.woff') format('woff'),
        url('../fonts/NotoSansRegular/NotoSans-Regular.ttf') format('truetype'),
        url('../fonts/NotoSansRegular/NotoSans-Regular.svg#NotoSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'PoppinsBold';
    src: url('../fonts/PoppinsBold/Poppins-Bold.eot');
    src: url('../fonts/PoppinsBold/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PoppinsBold/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/PoppinsBold/Poppins-Bold.woff') format('woff'),
        url('../fonts/PoppinsBold/Poppins-Bold.ttf') format('truetype'),
        url('../fonts/PoppinsBold/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'PoppinsItalic';
    src: url('../fonts/PoppinsItalic/Poppins-Italic.eot');
    src: url('../fonts/PoppinsItalic/Poppins-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PoppinsItalic/Poppins-Italic.woff2') format('woff2'),
        url('../fonts/PoppinsItalic/Poppins-Italic.woff') format('woff'),
        url('../fonts/PoppinsItalic/Poppins-Italic.ttf') format('truetype'),
        url('../fonts/PoppinsItalic/Poppins-Italic.svg#Poppins-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'PoppinsLight';
    src: url('../fonts/PoppinsLight/Poppins-Light.eot');
    src: url('../fonts/PoppinsLight/Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PoppinsLight/Poppins-Light.woff2') format('woff2'),
        url('../fonts/PoppinsLight/Poppins-Light.woff') format('woff'),
        url('../fonts/PoppinsLight/Poppins-Light.ttf') format('truetype'),
        url('../fonts/PoppinsLight/Poppins-Light.svg#Poppins-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'PoppinsRegular';
    src: url('../fonts/PoppinsRegular/Poppins-Regular.eot');
    src: url('../fonts/PoppinsRegular/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PoppinsRegular/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/PoppinsRegular/Poppins-Regular.woff') format('woff'),
        url('../fonts/PoppinsRegular/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/PoppinsRegular/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'PoppinsSemiBold';
    src: url('../fonts/PoppinsSemiBold/Poppins-SemiBold.eot');
    src: url('../fonts/PoppinsSemiBold/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PoppinsSemiBold/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/PoppinsSemiBold/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/PoppinsSemiBold/Poppins-SemiBold.ttf') format('truetype'),
        url('../fonts/PoppinsSemiBold/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

