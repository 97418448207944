.interna__dicas__e__curiosidades{
	padding-top: 106px;
	padding-bottom: 150px;

	.titulo__dicas{
		font-family: 'AclonicaRegular';
		font-size: 35px;
		line-height: 1.2;
		color: #5e2a03;
		text-align: center;
	}

	.subtitulo__dicas{
		font-family: 'PoppinsRegular';
		font-size: 18px;
		line-height: 1.2;
		color: #666666;
		text-align: center;

		margin-bottom: 30px;
	}

	.segura__cards__dicas{
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		row-gap: 20px;

		padding-bottom: 80px;

		.card__dica{
			display: flex;
			align-items: center;
			justify-content: space-between;

			border-radius: 150px;

			text-decoration: none;
			padding: 30px;

			@include media-breakpoint-down(md){
				flex-direction: column;
			}

			.segura__imagem__dica{
				border-radius: 50%;

				img{
					border-radius: 50%;
				}

				@include media-breakpoint-down(md){
					margin-bottom: 20px;
				}
			}

			.segura__desc__dica{

				@include media-breakpoint-down(md){
					margin-bottom: 20px;
				}

				.nome__dica{
					font-family: 'AclonicaRegular';
					font-size: 24px;
					line-height: 1.2;
					color: #5e2a03;

					margin-bottom: 10px;

					position: relative;

					&:after{
						content: '';

						position: absolute;

						left: 0;
						bottom: -4px;

						width: 206px;
						height: 2px;

						background: rgb(249,187,37);
						background: linear-gradient(90deg, rgba(249,187,37,1) 22%, rgba(94,42,3,1) 22%);
					}
				}

				.desc__dica{
					font-family: 'PoppinsRegular';
					font-size: 18px;
					line-height: 1.1;
					color: #666666;

					max-width: 676px;
					--linhas: 7;
				}
			}

			.btn__card__dica{
				display: flex;
				align-items: center;
				justify-content: center;

				width: 120px;
				height: 30px;

				border-radius: 30px;

				background-color: #5e2a03;

				font-family: 'AclonicaRegular';
				font-size: 16px;
				line-height: 1.2;
				color: #f9bb25;
			}

			&:hover{
				background-color: #5e2a03;
				transition: all 0.5s linear;

				.segura__desc__dica{

					.nome__dica{
						color: #f9bb25;
					}

					.desc__dica{
						color: #FFF;
					}
				}

				.btn__card__dica{
					background-color: #f9bb25;
					color: #5e2a03;
				}
			}
		}
	}

	.btn__dica{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 250px;
		height: 60px;

		border-radius: 30px;

		background-color: #5e2a03;

		font-family: 'PoppinsSemiBold';
		font-size: 16px;
		line-height: 1.2;
		color: #FFF;
		text-decoration: none;

		margin-left: auto;
		margin-right: auto;
	}
}

