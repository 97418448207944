.interna__contato{
	padding-top: 106px;
	padding-bottom: 150px;

	.titulo__contato{
		font-family: 'AclonicaRegular';
		font-size: 35px;
		line-height: 1.2;
		color: #5e2a03;
		text-align: center;

		margin-bottom: 50px;
	}

	.contato__input{
		border: none;
		display: block;
		width: 100%;
		background-color: #e0e0e0;
		color: #666666;
		padding: 10px 25px;
		font-family: 'PoppinsLight';
		resize: none;

		border-radius: 30px;

		&:focus{
			outline: none;
		}

		&::placeholder{
			color: #666666;
		}
	}

	.form__contato {
		max-width: 970px;
		margin-right: auto;
		margin-left: auto;
		position: relative;

		p{
			margin: 0 !important;
		}

		.captcha{
			position: absolute;
			right: 0;
			bottom: -60px;

			@include media-breakpoint-down(md){
				position: relative;
				right: unset;
				bottom: unset;
				margin-left: auto;
				margin-right: auto;
				margin-top: 30px;
				margin-bottom: 15px;
			}
		}

		@include media-breakpoint-down(md) {
			display: block;
		}
		grid-template-columns: 1fr 1fr;
		column-gap: 10px;

		.group{
			width: 100%;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 10px;

			@include media-breakpoint-down(md){
				grid-template-columns: 1fr;
			}
		}

		.form-group {
			margin-bottom: -12px;
			width: 100%;
		}

		.enviar__botao {
			display: flex;
			align-items: center;
			justify-content: center;

			width: 160px;
			height: 25px;

			border: none;
			border-radius: 30px;

			background-color: #5e2a03;
			color: #FFF;

			font-family: 'PoppinsRegular';
			font-size: 15px;
			color: #FFF;
			text-decoration: none;
		}

		.custom__check {
			position: fixed;
			right: 200vw;

			&:checked {
				& ~ .custom__label {
					&:after {
						opacity: 1;
					}
				}
			}
		}

		.custom__label {
			position: relative;
			padding-left: 25px;
			cursor: pointer;
			color: #5e2a03;

			&::before {
				content: '';
				position: absolute;
				width: 15px;
				height: 15px;
				border-radius: 50%;
				border: 2px solid #5e2a03;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			&::after {
				content: '';
				position: absolute;
				width: 9px;
				height: 9px;
				border-radius: 50%;
				background-color: #5e2a03;
				left: 3px;
				top: 50%;
				transform: translateY(-50%);
				opacity: 0;
				transition: all 0.3s linear;
			}
		}
	}

	.row__personalizada{
		padding-top: 120px;

		position: relative;

		&:after{
			content: '';

			position: absolute;

			width: 4px;
			height: 140px;

			background-color: #f9bb25;

			left: calc(50% - 2px);
			top: calc(50% - 70px);

			@include media-breakpoint-down(md){
				display: none;
			}
		}

		.col__infos{

			.titulo__infos{
				font-family: 'PoppinsBold';
				font-size: 16px;
				line-height: 1.2;
				color: #434343;

				padding-bottom: 22px;
			}

			.segura__email{
				display: flex;
				align-items: center;

				padding-bottom: 12px;

				.icone__email{
					color: #5e2a03;

					padding-right: 10px;
				}

				span{
					font-family: 'PoppinsBold';
					font-size: 14px;
					line-height: 1.2;
					color: #434343;

					padding-right: 4px;
				}

				.link__email{
					font-family: 'PoppinsBold';
					font-size: 14px;
					line-height: 1.2;
					color: #434343;
				}
			}

			.segura__telefones{
				display: flex;
				align-items: center;

				padding-bottom: 12px;
				.icone__telefone{
					color: #5e2a03;

					padding-right: 10px;
				}

				span{
					font-family: 'PoppinsBold';
					font-size: 14px;
					line-height: 1.2;
					color: #434343;

					padding-right: 4px;
				}

				.segura__links__telefone{
					display: flex;
					align-items: center;

					.link__telefone{
						font-family: 'PoppinsBold';
						font-size: 14px;
						line-height: 1.2;
						color: #434343;
					}

					.barrinha{
						color: #434343;
					}
				}
			}

			.segura__horario {
				display: flex;
				align-items: center;

				padding-bottom: 12px;
				.icone__horario {
					color: #5e2a03;

					padding-right: 10px;
				}

				span{
					font-family: 'PoppinsBold';
					font-size: 14px;
					line-height: 1.2;
					color: #434343;

					padding-right: 4px;
				}

				.desc__horario {
					font-family: 'PoppinsBold';
					font-size: 14px;
					line-height: 1.2;
				}
			}

			.segura__endereco{
				display: flex;
				align-items: center;

				padding-bottom: 12px;
				.icone__endereco {
					color: #5e2a03;

					padding-right: 10px;
				}

				span{
					font-family: 'PoppinsBold';
					font-size: 14px;
					line-height: 1.2;
					color: #434343;

					padding-right: 4px;
				}

				.desc__endereco {
					font-family: 'PoppinsBold';
					font-size: 14px;
					line-height: 1.2;
					max-width: 280px;
				}
			}
		}

		.col__mapa{
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.box__mapa{
				max-width: 488px;
				max-height: 250px;

				border-radius: 30px;
				border: 3px solid #5e2a03;
			}
		}
	}
}

