.interna__roteiros{
	padding-top: 106px;
	padding-bottom: 150px;

	.titulo__roteiros{
		font-family: 'AclonicaRegular';
		font-size: 35px;
		line-height: 1.2;
		color: #5e2a03;
		text-align: center;
	}

	.desc__roteiros{
		font-family: 'PoppinsRegular';
		font-size: 18px;
		line-height: 1.2;
		color: #666666;
		text-align: center;
	}

	.categoria__header{
		background: #5e2a03;
		color: #f9bb25;
		font-family: 'AclonicaRegular';
		font-size: 28px;
		text-align: center;
		padding: 12px 40px;
	}

	.categorias__listagem{
		overflow: hidden;
		background-color: #e4e4e4;

		.categoria__box{
			padding: 20px !important;
		}
	}

	.categoria__lista{
		padding-left: 0px;
		margin-bottom: 0px;
		list-style: none;

		li{
			margin-bottom: 20px;
		}

		@include media-breakpoint-down(md){
			margin-bottom: 10px;
		}
	}

	.categoria__item__interna{
		display: flex;
		font-family: 'AclonicaRegular';
		font-size: 15px;
		color: #5e2a03;
		transition: color .4s linear;
	}

	.subcategoria__lista{
		padding-left: 0;
		list-style: none;
		margin-bottom: 5px;
		padding-top: 5px;

		li{
			margin-bottom: 5px;
		}

		.subcategoria__item{
			margin-bottom: 5px;

			.subcategoria__link{
				font-size: 15px;
				line-height: 1.2;
				font-family: 'PoppinsRegular';
				color: #666666;

				&:hover{
					color: #5e2a03;
				}
			}
		}
	}

	.segura__categoria__controls{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;

		position: relative;

		&::after{
			content: '';

			width: 100%;
			height: 1px;

			background-color: #d1d1d1;

			position: absolute;
			bottom: -10px;
			left: calc(50% - 50%);
		}

		button{
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: transparent;
			color: #5e2a03;
			border-radius: 50%;
			border: none;
			padding: 0px;
			font-size: 16px;
			line-height: 16px;
			margin-left: 5px;
			margin-bottom: 2px;
			transition: all 0.3s linear;
			padding-top: 1px;

			.fa-sort-down{
				display: block;
			}
			.fa-caret-left{
				display: none;
			}

			&:focus{
				outline: none;
			}
			&[aria-expanded="true"] {
				.fa-sort-down{
					display: none;
				}
				.fa-caret-left{
					display: block;
				}
			}
		}
	}

	.row__personalizada{
		padding-top: 40px;
		padding-bottom: 100px;

		.col__categorias{
			@include media-breakpoint-down(md){
				padding-bottom: 40px;
			}
		}
	}

	.segura__cards__roteiros{
		display: grid;
		grid-template-columns: repeat(3, 1fr);

		gap: 30px;

		@include media-breakpoint-down(md){
			grid-template-columns: repeat(1, 1fr);
		}

		.card__roteiro{
			max-width: 255px;

			text-decoration: none;

			margin-left: auto;
			margin-right: auto;

			.card__roteiro__header{
				position: relative;

				&:after{
					background-image: url(../images/detalhe-destino.png);
					background-repeat: no-repeat;
					background-size: auto;
					background-position: center;
					width: 255px;
					height: 40px;
					z-index: 2;

					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					opacity: 0;
				}

				.data{
					display: flex;
					align-items: center;
					justify-content: center;

					width: 80px;
					height: 30px;

					background-color: #5e2a03;

					border-radius: 0 30px 30px 0;

					font-family: 'PoppinsRegular';
					font-size: 16px;
					line-height: 1.2;
					color: #FFF;

					position: absolute;

					left: 0;
					top: 24px;
				}
			}

			.card__roteiro__body{
				padding-top: 10px;

				.nome__roteiro{
					font-family: 'AclonicaRegular';
					font-size: 16px;
					line-height: 1.2;
					color: #5e2a03;
					text-align: center;
				}
			}

			&:hover{

				.card__roteiro__header{

					&:after{
						opacity: 1;
						transition: all 0.5s linear;
					}
				}
			}
		}
	}

	.btn__ver__mais{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 250px;
		height: 60px;

		border: none;
		border-radius: 30px;

		background-color: #5e2a03;
		color: #FFF;

		font-family: 'PoppinsSemiBold';
		font-size: 16px;
		color: #FFF;
		text-decoration: none;

		margin-right: auto;
		margin-left: auto;
	}
}

