.banner__carousel{
	max-width: 1920px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	overflow: hidden;

	.carousel-inner{
		max-width: 1920px;
		width: 100%;
		justify-content: center;
	}

	.carousel-indicators{
		bottom: 30px;

		@include media-breakpoint-down(md){
			bottom: 10px;
		}

		li{
			text-indent: 0;
			top: 0;
			border-bottom: 0;
			border-top: 0;
			width: 16px;
			height: 16px;
			opacity: 1;
			border: none;
			border-radius: 50%;
			position: relative;
			margin: 0px 4px;
			background-color: rgba(255, 255, 255, 0.34);
			&.active{
				background-color: #FFF;
			}
		}
	}

	@include media-breakpoint-up(lg){
		margin-top: -163px;
	}
}

.secao__roteiros{
	background-image: url(../images/background-destinos.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center;
	height: 1046px;

	background-color: #f7f4f2;

	padding-top: 100px;

	@include media-breakpoint-down(md){
		height: 1306px;
	}

	.titulo__roteiros{
		font-family: 'AclonicaRegular';
		font-size: 35px;
		line-height: 1.2;
		color: #5e2a03;
		text-align: center;
	}

	.desc__roteiros{
		font-family: 'PoppinsLight';
		font-size: 18px;
		line-height: 1.2;
		color: #666666;
		text-align: center;

		margin-bottom: 40px;
	}

	.owl__roteiros{
		padding-bottom: 60px;

		.card__roteiro{
			max-width: 255px;

			text-decoration: none;

			margin-left: auto;
			margin-right: auto;

			@include media-breakpoint-down(md){
				display: flex;
				flex-direction: column;
			}

			.card__roteiro__header{
				position: relative;

				&:after{
					background-image: url(../images/detalhe-destino.png);
					background-repeat: no-repeat;
					background-size: auto;
					background-position: center;
					width: 255px;
					height: 40px;
					z-index: 2;

					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					opacity: 0;
				}

				.data{
					display: flex;
					align-items: center;
					justify-content: center;

					width: 80px;
					height: 30px;

					background-color: #5e2a03;

					border-radius: 0 30px 30px 0;

					font-family: 'PoppinsRegular';
					font-size: 16px;
					line-height: 1.2;
					color: #FFF;

					position: absolute;

					left: 0;
					top: 24px;
				}
			}

			.card__roteiro__body{
				padding-top: 10px;

				.nome__roteiro{
					font-family: 'AclonicaRegular';
					font-size: 16px;
					line-height: 1.2;
					color: #5e2a03;
					text-align: center;
				}
			}

			&:hover{

				.card__roteiro__header{

					&:after{
						opacity: 1;
						transition: all 0.5s linear;
					}
				}
			}
		}

		.owl-nav {
			position: relative;

			@include media-breakpoint-down(md){
				display: flex;
				align-items: center;
				justify-content: center;

				padding-top: 30px;
			}

			&.disabled{
				display: none;
			}

			button.owl-next {
				display: flex;
				align-items: center;
				justify-content: center;

				width: 40px;
				height: 40px;

				background-color: #5e2a03;

				border: none;
				border-radius: 50%;

				font-size: 18px;
				color: #FFF;

				position: absolute;

				top: calc(50% - 161px);
				right: -50px;

				@include media-breakpoint-down(md){
					position: unset;
					top: unset;
					right: unset;

					margin: 0px 4px;
				}

				&:hover {
					background-color: #f9bb25;
					transition: all 0.4s linear;
				}
			}

			button.owl-prev {
				display: flex;
				align-items: center;
				justify-content: center;

				width: 40px;
				height: 40px;

				background-color: #5e2a03;

				border: none;
				border-radius: 50%;

				font-size: 18px;
				color: #FFF;

				position: absolute;

				top: calc(50% - 161px);
				left: -50px;

				@include media-breakpoint-down(md){
					position: unset;
					top: unset;
					left: unset;

					margin: 0px 4px;
				}

				&:hover {
					background-color: #f9bb25;
					transition: all 0.4s linear;
				}
			}
		}
	}

	.btn__roteiros{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 250px;
		height: 60px;

		border-radius: 50px;

		background-color: #5e2a03;

		font-family: 'PoppinsBold';
		font-size: 16px;
		line-height: 1.2;
		color: #FFF;
		text-decoration: none;

		margin-right: auto;
		margin-left: auto;
	}
}

.secao__destinos{
	padding-top: 100px;

	.titulo__destinos{
		font-family: 'AclonicaRegular';
		font-size: 35px;
		line-height: 1.2;
		color: #5e2a03;
		text-align: center;
	}

	.desc__destinos{
		font-family: 'PoppinsLight';
		font-size: 18px;
		line-height: 1.2;
		color: #666666;
		text-align: center;

		margin-bottom: 40px;
	}

	.owl__destinos{
		padding-bottom: 60px;

		.card__excursao{
			max-width: 350px;

			text-decoration: none;

			margin-left: auto;
			margin-right: auto;

			@include media-breakpoint-down(md){
				display: flex;
				flex-direction: column;
			}

			.card__excursao__header{
				position: relative;

				&:after{
					background-image: url(../images/detalhe-excursao.png);
					background-repeat: no-repeat;
					background-size: auto;
					background-position: center;
					width: 350px;
					height: 50px;
					z-index: 2;

					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					opacity: 0;
				}
			}

			.card__excursao__body{
				background-color: #5e2a03;

				padding: 20px 30px;
				position: relative;

				&:after{
					background-image: url(../images/detalhe-botao.png);
					background-repeat: no-repeat;
					background-size: auto;
					background-position: center;
					width: 350px;
					height: 15px;
					z-index: 1;

					content: '';
					position: absolute;
					left: 0;
					top: -15px;
				}

				.nome__excursao{
					font-family: 'AclonicaRegular';
					font-size: 16px;
					line-height: 1.2;
					color: #FFF;
				}
			}

			&:hover{

				.card__excursao__header{

					&:after{
						opacity: 1;
						transition: all 0.5s linear;
					}
				}
			}
		}

		.owl-nav {
			position: relative;

			@include media-breakpoint-down(md){
				display: flex;
				align-items: center;
				justify-content: center;

				padding-top: 30px;
			}

			&.disabled{
				display: none;
			}

			button.owl-next {
				display: flex;
				align-items: center;
				justify-content: center;

				width: 40px;
				height: 40px;

				background-color: #5e2a03;

				border: none;
				border-radius: 50%;

				font-size: 18px;
				color: #FFF;

				position: absolute;

				top: calc(50% - 290px);
				right: -50px;

				@include media-breakpoint-down(md){
					position: unset;
					top: unset;
					right: unset;

					margin: 0px 4px;
				}

				&:hover {
					background-color: #f9bb25;
					transition: all 0.4s linear;
				}
			}

			button.owl-prev {
				display: flex;
				align-items: center;
				justify-content: center;

				width: 40px;
				height: 40px;

				background-color: #5e2a03;

				border: none;
				border-radius: 50%;

				font-size: 18px;
				color: #FFF;

				position: absolute;

				top: calc(50% - 290px);
				left: -50px;

				@include media-breakpoint-down(md){
					position: unset;
					top: unset;
					left: unset;

					margin: 0px 4px;
				}

				&:hover {
					background-color: #f9bb25;
					transition: all 0.4s linear;
				}
			}
		}
	}

	.btn__destinos{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 250px;
		height: 60px;

		border-radius: 50px;

		background-color: #f9bb25;

		font-family: 'PoppinsBold';
		font-size: 16px;
		line-height: 1.2;
		color: #FFF;
		text-decoration: none;

		margin-right: auto;
		margin-left: auto;
	}
}

.secao__sobre__nos{
	background-color: #f7f4f2;
	padding-top: 90px;
	padding-bottom: 120px;

	@include media-breakpoint-down(md){
		padding-top: unset;
	}

	.titulo__sobre__nos{
		font-family: 'AclonicaRegular';
		font-size: 35px;
		line-height: 1.2;
		color: #5e2a03;

		max-width: 485px;

		margin-bottom: 20px;
	}

	.desc__sobre__nos{
		font-family: 'PoppinsLight';
		font-size: 16px;
		line-height: 1.2;
		color: #666666;
		text-align: justify;

		margin-bottom: 38px;
	}

	.btn__sobre__nos{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 250px;
		height: 60px;

		background-color: #5e2a03;

		border-radius: 50px;

		font-family: 'PoppinsSemiBold';
		font-size: 16px;
		line-height: 1.2;
		color: #FFF;
		text-decoration: none;
	}

	.col__quem{
		@include media-breakpoint-down(md){
			padding-top: 30px;
		}
	}

	.titulo__quem{
		font-family: 'AclonicaRegular';
		font-size: 35px;
		line-height: 1.2;
		color: #5e2a03;

		margin-bottom: 20px;
	}

	.card__quem{
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include media-breakpoint-down(md){
			flex-direction: column;
		}

		.segura__icone{
			@include media-breakpoint-down(md){
				padding-bottom: 30px;
			}
		}

		.segura__desc{
			max-width: 362px;

			.desc__quem{
				font-family: 'PoppinsLight';
				font-size: 16px;
				line-height: 1.2;
				color: #666;
				text-align: justify;
			}

			.nome__quem{
				font-family: 'AclonicaRegular';
				font-size: 20px;
				line-height: 1.2;
				color: #5e2a03;
			}
		}
	}
}


.politica__de__privacidade{
	padding-top: 100px;

	.container__politica__de__privacidade {
		padding-top: 50px;
		padding-bottom: 100px;
		.politica__titulo {
			font-family: 'AclonicaRegular';
			font-size: 35px;
			line-height: 1.2;
			color: #5e2a03;
			text-align: center;

			margin-bottom: 30px;
		}
		.content__editable {
			font-family: 'PoppinsLight';
			font-size: 16px;
			line-height: 1.2;
			color: #666;
		}

		.text-center {
			padding-bottom: 20px;
		}

		.btn-voltar {
			display: flex;
			align-items: center;
			justify-content: center;

			width: 250px;
			height: 60px;

			border: none;
			border-radius: 50px;

			background-color: #5e2a03;

			font-family: 'PoppinsBold';
			font-size: 16px;
			line-height: 1.2;
			color: #FFF;
			text-decoration: none;

			margin-right: auto;
			margin-left: auto;
		}
	}
}

.termos__de__uso{
	padding-top: 100px;

	.container__termos__de__uso {
		padding-top: 50px;
		padding-bottom: 100px;
		.termos__titulo {
			font-family: 'AclonicaRegular';
			font-size: 35px;
			line-height: 1.2;
			color: #5e2a03;
			text-align: center;

			margin-bottom: 30px;
		}
		.content__editable {
			font-family: 'PoppinsLight';
			font-size: 16px;
			line-height: 1.2;
			color: #666;
		}

		.text-center {
			padding-bottom: 20px;
		}

		.btn-voltar {
			display: flex;
			align-items: center;
			justify-content: center;

			width: 250px;
			height: 60px;

			border: none;
			border-radius: 50px;

			background-color: #5e2a03;

			font-family: 'PoppinsBold';
			font-size: 16px;
			line-height: 1.2;
			color: #FFF;
			text-decoration: none;

			margin-right: auto;
			margin-left: auto;
		}
	}
}
