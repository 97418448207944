.bg__menu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 1050;
	animation: fadeIn 0.5s linear;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}

.redes__sociais{
	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 20px;

	@include media-breakpoint-down(md){
		padding-left: 0;
	}

	a{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 30px;
		height: 30px;

		background-color: rgba(255, 255, 255, 0.34);

		border-radius: 50%;

		color: #FFF;
		text-decoration: none;

		margin: 0px 2px;

		&:hover{
			background-color: #34e0a1;
			color: #000;

			transition: all 0.5s linear;
		}
	}
}

.dropdown__controls{
	position: relative;

	button{
		width: 20px;
		height: 20px;
		position: absolute;
		top: calc(50% - 10px);
		right: 10px;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		line-height: 12px;
		border-radius: 50%;
		transition: all 0.3s linear;
		padding: 0px;
		padding-top: 2px;
		&[aria-expanded="true"] {
			transform: rotate(90deg);
		}
	}
}

.dropmenu{

	ul{
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;
	}

	a{
		display: block;
		width: 100%;
		padding: 10px;
		height: 45px;

		color: #fff;
		font-size: 14px;
		line-height: 16px;

		&:hover{
			color: #2c4290;
			text-decoration: none;
			background-color: #FFF;
			transition: all 0.5s linear;
		}
	}
}

@include media-breakpoint-up(lg) {

	.topo__interna{
		background-color: #5e2a03;
		background-image: url(../images/background-header.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		min-height: 130px;
	}

	.main__cima{
		padding: 10px 0px;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.topo__mobile{
		display: none;
	}

	.segura__logo{
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0px 10px;
	}

	.main__menu{
		display: flex;
		text-align: center;
		justify-content: space-between;
		flex-grow: 1;
		font-family: 'PoppinsLight';
		line-height: 1.2;
		margin: 0;
		padding: 0;

		.menu__item{
			list-style-type: none;
			display: flex;

			a{
				display: flex;
				align-items: center;

				position: relative;

				font-family: 'PoppinsRegular';
				font-size: 16px;
				text-decoration: none;
				color: #fff;

				&:before{
					width: 100%;
					height: 2px;

					content: '';
					position: absolute;

					background-color: #f9bb25;

					bottom: -10px;
					left: calc(50% - 50%);

					opacity: 0;
					transition: all 0.5s linear;
				}
			}

			&:hover{

				a {
					color: #f9bb25;

					transition: all 0.5s linear;

					&:before{
						opacity: 1;
					}
				}

			}

			&.menu__item--active{

				a{
					font-family: 'PoppinsBold';
					color: #f9bb25;

					&:before{
						opacity: 1;
					}
				}
			}

			.dropdown__controls{
				display: flex;

				button{
					display: none;
				}
			}
		}
	}

	.main__menu{
		.menu__item{
			position: relative;
			&:hover{
				.dropmenu{
					display: block;
				}
			}
		}
	}

	.dropmenu {
		position: absolute;
		top: 29px !important;

		width: 130px;
		max-height: 1000px;
		background-color: #5e2a03;

		list-style: none;

		padding: 18px 0px;
		margin-bottom: 0px;
		margin: 0px !important;
		z-index: 1000;
		border-radius: 0px 0px 10px 10px;

		&::after{
			width: 100%;
			height: 8px;

			content: '';
			position: absolute;

			background-color: transparent;

			top: -10px;
			left: 0;
		}

		li {
			display: flex;

			.link__categoria {
				display: flex;
				align-items: center;
				justify-content: center;

				padding: 10px !important;

				width: 100%;
				height: 100%;

				text-align: center;

				font-family: 'PoppinsLight' !important;
				font-size: 17px;
				color: #FFF !important;

				&:before {
					display: none;
				}

				&:hover {
					transition: all 0.3s linear;
					color: #5e2a03 !important;
					text-decoration: none;
					background-color: #FFF;
				}
			}
		}
	}

	.topo__main{
		position: relative;
		z-index: 1000;
		background-color: transparent;
	}

	@include media-breakpoint-only(lg) {
		.segura__logo{
			max-width: 285px;
		}
	}

}

@include media-breakpoint-down(md){

	.main__cima{
		order: 2;
	}

	.main__baixo{
		order: 1;
	}

	.segura__logo{
		display: flex;
		align-items: center;
		width: 90%;
	}

	.main__menu{
		display: flex;
		text-align: center;
		justify-content: center;
		flex-direction: column;
		font-family: 'PoppinsLight';
		font-size: 20px;
		margin-top: 20px;
		padding: 0px;
		border-top: 2px solid #FFF;
		border-bottom: 2px solid #FFF;

		.menu__link{
			display: flex;
			align-items: center;

			width: 100%;

			font-family: 'PoppinsRegular';
			font-size: 17px;
			text-decoration: none;
			color: #FFF;

			padding: 10px;
		}
		.menu__item{
			list-style-type: none;
			text-align: center;
			justify-content: center;
			&:hover{
				transition: all 0.5s linear;
				background-color: #FFF;

				.menu__link{
					color: #5e2a03;
				}
			}

			&.menu__item--active{
				background-color: #FFF;

				.menu__link{
					color: #5e2a03;
				}
			}
		}
	}

	.dropmenu{
		list-style: none;
		padding: 0px;
		margin: 0px;

		.link__categoria{
			display: block;

			display: flex;
			align-items: center;
			justify-content: center;

			padding: 10px!important;

			width: 100%;
			height: 100%;

			text-align: center;

			background-color: #5e2a03;
			font-family: 'PoppinsLight'!important;
			font-size: 17px;
			color: #FFF!important;

			&:hover{
				color: #5e2a03!important;
			}
		}
	}

	.topo__main{
		width: 250px;
		height: 100%;
		overflow-y: auto;
		position: fixed;
		background-color: #5e2a03;
		left: -250px;
		z-index: 1080;
		transition: all 0.5s linear;
		display: flex;
		flex-direction: column;

		.container{
			padding: 0px;

			.segura__logo{
				display: flex;
				align-items: center;
				justify-content: center;

				width: 100%;
			}
		}

		&.shown{
			left: 0px;
		}
	}

	.topo__mobile{
		background-color: #5e2a03;
		box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.43);
		-webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.43);
		-moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.43);

		.container{
			padding-bottom: 10px;
			padding-top: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.mbl__toggler{
			background-color: transparent;
			border: none;
			color: #FFF;
			font-size: 30px;
		}
	}
}
