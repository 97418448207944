footer{
	background-color: #5e2a03;
	background-image: url(../images/background-footer.png);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 950px;
}

.rodape__newsletter{
	padding-top: 200px;

	.titulo__newsletter{
		font-family: 'AclonicaRegular';
		font-size: 35px;
		color: #5e2a03;
		line-height: 1.2;
		text-align: center;
	}

	.subtitulo__newsletter{
		font-family: 'PoppinsRegular';
		font-size: 17px;
		color: #FFF;
		line-height: 1.2;
		text-align: center;

		margin-bottom: 20px;
	}

	.form-group{
		width: 100%;
	}

	.newsletter__input{
		border: none;
		display: block;
		width: 100%;
		height: 54px;
		background-color: #FFF;
		color: #666666;
		padding: 15px 25px;
		font-family: 'PoppinsLight';
		resize: none;

		border-radius: 54px;

		&:focus{
			outline: none;
		}

		&::placeholder{
			color: #666666;
		}
	}

	.form__newsletter{
		max-width: 730px;
		margin-right: auto;
		margin-left: auto;
		position: relative;

		p{
			height: 54px;
			display: flex;
			align-items: center;
			margin-bottom: 0;
		}


		@include media-breakpoint-down(md){
			display: block;
		}

		.form-group{
			margin-bottom: 10px;
		}

		.enviar__botao{
			border: none;

			background-color: transparent;
			color: #5e2a03;
			position: absolute;
			right: 20px;
			top: 12px;
			font-size: 20px;
		}

		.custom__check{
			position: fixed;
			left: 200vw;

			&:checked{
				& ~ .custom__label{
					&:after{
						opacity: 1;
					}
				}
			}
		}
		.custom__label{
			position: relative;
			padding-left: 25px;
			cursor: pointer;
			color: #FFF;

			&::before{
				content: '';
				position: absolute;
				width: 15px;
				height: 15px;
				border-radius: 50%;
				border: 2px solid #FFF;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
			&::after{
				content: '';
				position: absolute;
				width: 9px;
				height: 9px;
				border-radius: 50%;
				background-color: #FFF;
				left: 3px;
				top: 50%;
				transform: translateY(-50%);
				opacity: 0;
				transition: all 0.3s linear;
			}
		}
	}
}

.rodape__conteudo{
	padding-top: 180px;

	.row__personalizada {
		padding-bottom: 50px;

		.col__logo{
			@include media-breakpoint-down(md){
				padding-top: 20px;
				padding-bottom: 20px;
			}

			.segura__logo__footer{

				img{
					padding-bottom: 20px;
				}

				@include media-breakpoint-down(md){
					display: flex;
					justify-content: center;
				}
			}

			.desc__empresa{
				font-family: 'PoppinsLight';
				font-size: 15px;
				color: #FFF;
				line-height: 1.2;
			}
		}

		.col__institucional{
			@include media-breakpoint-down(md){
				padding-top: 20px;
				padding-bottom: 20px;
			}

			.titulo__institucional{
				font-family: 'AclonicaRegular';
				font-size: 20px;
				color: #FFF;
				line-height: 1.2;

				padding-bottom: 24px;

				@include media-breakpoint-down(md){
					text-align: center;
				}
			}

			.rodape__segura__menu{
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				flex-direction: column;
				list-style: none;

				font-family: 'PoppinsLight';
				font-size: 15px;
				color: #FFF;
				line-height: 1.2;

				padding: unset;
				@include media-breakpoint-down(md){
					text-align: center;
				}

				li{
					margin-bottom: 30px;
				}

				a{
					color: #FFF;
					text-decoration: none;
				}
			}

		}

		.col__contato{
			@include media-breakpoint-down(md){
				padding-top: 20px;
				padding-bottom: 20px;
			}

			.titulo__contato{
				font-family: 'AclonicaRegular';
				font-size: 20px;
				color: #FFF;
				line-height: 1.2;

				padding-bottom: 24px;

				@include media-breakpoint-down(md){
					text-align: center;
				}
			}

			.segura__telefones{
				display: flex;
				align-items: center;

				padding-bottom: 24px;

				@include media-breakpoint-down(md){
					justify-content: center;
					flex-direction: column;
				}

				.segura__icone{
					display: flex;
					align-items: center;
					justify-content: center;

					width: 40px;
					height: 40px;
					border-radius: 50%;

					background-color: rgba(255, 255, 255, 0.34);

					color: #FFF;
					font-size: 18px;

					margin-right: 12px;

					@include media-breakpoint-down(md){
						margin-left: unset;
						margin-bottom: 12px;
					}
				}

				.segura__link__contato{

					.link__tel{
						font-family: 'PoppinsLight';
						font-size: 15px;
						color: #FFF;
						line-height: 1.2;

						span{
							font-family: 'PoppinsSemiBold';
						}
					}
				}
			}

			.segura__email{
				display: flex;
				align-items: center;

				padding-bottom: 24px;

				@include media-breakpoint-down(md){
					justify-content: center;
					flex-direction: column;
				}

				.segura__icone{
					display: flex;
					align-items: center;
					justify-content: center;

					width: 40px;
					height: 40px;
					border-radius: 50%;

					background-color: rgba(255, 255, 255, 0.34);

					color: #FFF;
					font-size: 18px;

					margin-right: 12px;

					@include media-breakpoint-down(md){
						margin-left: unset;
						margin-bottom: 12px;
					}
				}

				.link__email{
					font-family: 'PoppinsLight';
					font-size: 15px;
					color: #FFF;
					line-height: 1.2;
				}
			}

			.segura__endereco{
				display: flex;
				align-items: center;

				@include media-breakpoint-down(md){
					justify-content: center;
					flex-direction: column;
				}

				.segura__icone{
					display: flex;
					align-items: center;
					justify-content: center;

					width: 40px;
					height: 40px;
					border-radius: 50%;

					background-color: rgba(255, 255, 255, 0.34);

					color: #FFF;
					font-size: 18px;

					margin-right: 12px;

					@include media-breakpoint-down(md){
						margin-left: unset;
						margin-bottom: 12px;
					}
				}

				.desc__endereco{
					font-family: 'PoppinsLight';
					font-size: 15px;
					color: #FFF;
					line-height: 1.2;

					max-width: 240px;

					@include media-breakpoint-down(md){
						text-align: center;
					}

					p{
						margin: 0;
					}
				}
			}
		}
	}
}

.rodape__creditos{
	font-family: 'PoppinsLight';
	color: #000000;

	.container__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;

		padding: 16px 0px;

		border-top: 2px solid #FFF;

		@include media-breakpoint-down(md) {
			padding: 16px 10px;
		}

		.creditos {
			color: #FFF;
			font-size: 12px;
			font-family: 'NotoSansRegular';
			text-align: center;

			@include media-breakpoint-down(md) {
				padding-bottom: 20px;
			}

			span {
				font-family: 'NotoSansBold';
			}

			svg {
				margin-left: 5px;
				color: #FFF;
			}

			@include media-breakpoint-down(md) {
				text-align: start;
			}
		}

		.redes__footer{
			display: flex;
			align-items: center;
			justify-content: flex-start;

			@include media-breakpoint-down(md){
				padding-bottom: 30px;
				justify-content: center;
			}

			a{
				display: flex;
				align-items: center;
				justify-content: center;

				width: 40px;
				height: 40px;
				border-radius: 50%;

				background-color: rgba(255, 255, 255, 0.34);

				color: #FFF;
				font-size: 19px;
				text-decoration: none;

				margin: 0px 2px;

				&:hover{
					background-color: #f9bb25;
					color: #FFF;

					transition: all 0.5s linear;
				}
			}
		}
	}
}

