.interna__sobre__nos{
	padding-top: 106px;
	padding-bottom: 150px;

	.titulo__sobre__nos{
		font-family: 'AclonicaRegular';
		font-size: 35px;
		line-height: 1.2;
		color: #5e2a03;
		text-align: center;
	}

	.row__personalizada{
		padding-top: 60px;

		@include media-breakpoint-down(md){
			padding-top: 20px;
		}

		.col__infos{

			.subtitulo__sobre__nos{
				font-family: 'PoppinsBold';
				font-size: 16px;
				line-height: 1.2;
				color: #666666;
				text-align: justify;

				margin-bottom: 10px;
			}

			.desc__sobre__nos{
				font-family: 'PoppinsLight';
				font-size: 16px;
				line-height: 1.2;
				color: #666666;
				text-align: justify;
			}
		}
	}
}

